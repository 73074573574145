import type { ImageGroup } from "@commerce/app.types";

import type {
  Image,
  Product,
} from "~/commerce-sap/.server/api/generated/__generated_apis";
import { IMAGES_HOST } from "~/lib/constants";

const MEDIA = {
  plp: ["$plp_pwa_desktop$", ""] as const,
  pdp: ["$pdp_desktop_pwa$", ""] as const,
  thumb: ["$pdp_thumb$", ""] as const,
} as const;
const isMedia = (type: string): type is keyof typeof MEDIA => {
  return type in MEDIA;
};

export const getImageLink = (imageLink: string) => {
  return `${
    imageLink?.charAt(0) === "/" ? `${IMAGES_HOST}${imageLink}` : imageLink
  }`;
};

export const getImageMediaLink = (
  image: Image,
  {
    type,
    quality,
    params,
  }: {
    type?: keyof typeof MEDIA;
    quality?: number;
    params: Record<string, string>;
  },
) => {
  const url = new URL(getImageLink(image.url ?? "")); // TODO: add default image
  let searchParams = url.searchParams;
  if (type && isMedia(type) && MEDIA[type])
    url.searchParams.set(MEDIA[type][0], MEDIA[type][1]);
  if (quality) url.searchParams.set("qlt", quality.toString());
  if (params)
    searchParams = new URLSearchParams({
      ...Object.fromEntries(searchParams),
      ...params,
    });

  return url.origin + url.pathname + "?" + searchParams.toString();
};

export const getImageGroup = (
  imagesGroups: ImageGroup[] | undefined,
  viewType?: "swatch" | "large",
  variationValues?: {
    [key: string]: any;
  },
) => {
  // Find the image group in which every variationAttribute's values contains the corresponding value in variationValues
  return imagesGroups?.find(imagesGroup => {
    if (imagesGroup.viewType !== viewType) return false;

    return imagesGroup.variationAttributes?.every(
      variationAttribute =>
        variationValues &&
        variationAttribute.values?.find(
          ({ value }) => value === variationValues[variationAttribute.id],
        ),
    );
  });
};

export const getProductImage = (
  product: Product,
  type: "zoom" | "product" | "thumbnail" | "store" | "cartIcon" = "zoom",
) => {
  if (!product.images || product.images.length === 0) return;

  return product.images.filter(v => v.format === type);
};
